import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { computed } from 'vue';

const isMobile = computed(() => /Mobi|Android/i.test(navigator.userAgent));

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    component: () =>
      isMobile.value
        ? import('@/layout/MobileLayout.vue')
        : import('@/layout/DesktopLayout.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () =>
          isMobile.value
            ? import('@/views/Mobile/MainPage.vue')
            : import('@/views/Desktop/MainPage.vue'),
      },
      {
        path: '/cart',
        name: 'cart',
        component: () =>
          isMobile.value
            ? import('@/views/Mobile/ProductCart.vue')
            : import('@/views/Desktop/ProductCart.vue'),
      },
      {
        path: '/products',
        name: 'products',
        component: () =>
          isMobile.value
            ? import('@/views/Mobile/ProductList.vue')
            : import('@/views/Desktop/ProductList.vue'),
      },
      {
        path: '/etalase',
        name: 'etalase',
        component: () =>
          isMobile.value
            ? import('@/views/Mobile/ProductEtalase.vue')
            : import('@/views/Desktop/ProductEtalase.vue'),
      },
      {
        path: '/search',
        name: 'search',
        component: () =>
          isMobile.value
            ? import('@/views/Mobile/SearchPage.vue')
            : import('@/views/Desktop/SearchPage.vue'),
      },
      {
        path: '/detail/:detailPath',
        name: 'product-detail',
        props: true,
        component: () =>
          isMobile.value
            ? import('@/views/Mobile/ProductDetail.vue')
            : import('@/views/Desktop/ProductDetail.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
