import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import Carousel from 'primevue/carousel';
import Image from 'primevue/image';
import BottomMenuBar from '@/components/commons/BottomMenuBar.vue';
import SearchBox from '@/components/commons/SearchBox.vue';
import BadgedCart from '@/components/commons/BadgedCart.vue';
import CategoryCard from '@/components/commons/CategoryCard.vue';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';

import { Icon } from '@iconify/vue';

import 'primevue/resources/themes/lara-light-teal/theme.css';
import Galleria from 'primevue/galleria';
import Skeleton from 'primevue/skeleton';
import Checkbox from '@/components/commons/Checkbox.vue';

const app = createApp(App);
app.use(router);
app.use(store);
app.use(PrimeVue);

app.component('Icon', Icon);
app.component('Dialog', Dialog);
app.component('Carousel', Carousel);
app.component('Image', Image);
app.component('BadgedCart', BadgedCart);
app.component('SearchBox', SearchBox);
app.component('BottomMenuBar', BottomMenuBar);
app.component('CategoryCard', CategoryCard);
app.component('Button', Button);
app.component('Galleria', Galleria);
app.component('Skeleton', Skeleton);
app.component('Checkbox', Checkbox);
app.component('OverlayPanel', OverlayPanel);

app.mount('#app');
