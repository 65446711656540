<script setup lang="ts">
import { computed, ref } from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import ColorThief from 'colorthief';
// const colorThief = new ColorThief();

const props = defineProps<{
  image: string;
  label: string;
  colorScheme?: {
    color: string;
    bgColor: string;
  };
}>();

const imageEl = ref<HTMLImageElement>();
const encodedLabel = computed(() =>
  encodeURIComponent(props.label.toLowerCase().replaceAll(' ', '-')),
);

// const getDominantColor = () => {
//   try {
//     const dominantColor = colorThief.getColor(imageEl.value);
//   } catch (error) {
//     console.log(error);
//   }
// };
</script>
<template>
  <router-link :to="{ name: 'products', query: { category: encodedLabel } }">
    <div class="category" :style="{ background: colorScheme?.bgColor }">
      <img :src="image" :alt="label" ref="imageEl" />
      <span class="label" :style="{ color: colorScheme?.color }">{{
        label
      }}</span>
    </div>
  </router-link>
</template>
<style scoped lang="scss">
@import '~scss/index';
.category {
  width: 105px;
  height: 105px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px;

  img {
    height: 40px;
    width: 40px;
    object-fit: cover;
  }

  .label {
    width: 100%;
    text-align: center;
    font-size: 0.786rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
}
</style>
