import store from '@/store';
import { ProductOrder } from '@/types/product.type';

/**
 * Generate WA Link with a list products to order
 * @format
 * - {{ count }} {{ name }}, Varian {{ variantName }}, size {{ size }}
 *
 * @example
 * - 2 Jamu Kunyit Asam
 *
 * @param products the list of products to order
 */
const generateOrderLink = (products: ProductOrder | ProductOrder[]): string => {
  const phoneNumber = store.state.template_messages.call_center_number;
  const baseURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=`;

  const openingText = store.state.template_messages.product_order_message;
  const listProducts = Array.isArray(products)
    ? ':\n\n' +
      products
        .map(({ count, name, variant, unit_type }) => {
          return `- ${count} ${unit_type ?? 'Gelas'} ${name}${
            variant
              ? ', varian ' + variant.name + ', ukuran ' + variant.size
              : ''
          }`;
        })
        .join('\n')
    : `${products.count} ${products.unit_type ?? 'Gelas'} ${products.name}${
        products.variant
          ? ', varian ' +
            products.variant.name +
            ', ukuran ' +
            products.variant.size
          : ''
      }`;

  return baseURL + encodeURIComponent(openingText + listProducts);
};

export const formatRupiah = (price: number) => {
  return `Rp${price.toLocaleString('id-ID')},-`;
};

export const countDiscount = (
  normalPrice: number,
  discount: number,
): number => {
  return normalPrice - (normalPrice * (discount ?? 0)) / 100;
};

type Sorting = {
  sortBy: 'By price' | 'By name';
  direction: 'Ascending' | 'Descending';
};

const sortProducts = (products: any, sort: Sorting) => {
  if (!products) return;

  if (sort.sortBy == 'By name') {
    sortbyName(products, sort.direction);
  } else {
    sortByPrice(products, sort.direction);
  }
};

const sortbyName = (products: any, direction: string) => {
  products.sort((a: any, b: any) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (direction === 'Descending') {
      return nameA === nameB ? 0 : nameA < nameB ? 1 : -1;
    } else {
      return nameA === nameB ? 0 : nameA > nameB ? 1 : -1;
    }
  });
};

const sortByPrice = (products: any, direction: string) => {
  products.sort((a: any, b: any) => {
    return direction === 'Descending' ? b.price - a.price : a.price - b.price;
  });
};

export const queryToRegExp = (query: string) => {
  const words = query.split(' ');
  const combinations = [];

  for (let i = 0; i < words.length; i++) {
    for (let j = i + 1; j <= words.length; j++) {
      combinations.push(words.slice(i, j).join(' '));
    }
  }

  const regexPattern = new RegExp(`(${combinations.join('|')})`, 'i');
  return regexPattern;
};

export const chatWhatsApp = () => {
  const number = store.state.template_messages.call_center_number;
  const message = store.state.template_messages.call_center_preserved_message;

  const link = `https://api.whatsapp.com/send?phone=${number}&text=${message}`;
  window.open(link, '_blank');
};

export default {
  generateOrderLink,
  sortProducts,
  queryToRegExp,
};
