import axios from 'axios';

const apikey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1hbXp6emRsY2J0aG1ibmZzaXdkIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTgyNDU3OTUsImV4cCI6MjAxMzgyMTc5NX0.GhLYN0E5Oe25GZDNby-ZOiFtlk0GrZ7JMLEAPv0ZYBM';
const SUPABASE = (params?: any) => {
  return axios.create({
    baseURL: 'https://mamzzzdlcbthmbnfsiwd.supabase.co/rest/v1/',
    headers: {
      Authorization: 'Bearer ' + apikey,
      apikey,
    },
    params,
  });
};

import { createClient } from '@supabase/supabase-js';

/**
 * New supabase services using JavaScript Client
 */
const supabase = createClient(
  'https://mamzzzdlcbthmbnfsiwd.supabase.co',
  apikey,
);

export const getBannerImages = async (
  mode: 'banner_desktop' | 'banner_mobile',
) => {
  return await SUPABASE().get(mode);
};

export const getCategories = async () => await SUPABASE().get('categories');

export const getProducts = async (type?: 'featured' | 'recommended') => {
  const base = supabase.from('products').select('*, category(*)');
  return type ? await base.eq(type, true) : await base;
};

export const getProductDetail = async (detailPath: string) =>
  await supabase
    .from('products')
    .select('*, category(*)')
    .eq('detail_path', detailPath)
    .single();

export const getProductsById = async (ids: number[]) => {
  return await SUPABASE().get(`products?id=in.(${ids})`);
};

export const getProductsByCategory = async (id: number) => {
  return await supabase.from('products').select().eq('category', id);
};

export const searchProducts = async (query: string) =>
  await supabase.from('products').select().ilike('name', `%${query}%`);

export const searchInCategory = async (query: string, id: number) =>
  await supabase
    .from('products')
    .select()
    .eq('category', id)
    .ilike('name', `%${query}%`);

export const getSearchRecommendation = async () =>
  await SUPABASE().get('search-recommendation?select=query');

export const searchCategory = async (query: string) =>
  await SUPABASE().get('categories?select=*&name=ilike.(' + query + ')');

export default supabase;

export const getTemplateMessages = async () =>
  supabase.from('template-messages').select().limit(1).single();
