<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
const emit = defineEmits<{
  (e: 'enter', query?: string): void;
  (e: 'iconClicked', query?: string): void;
}>();
const props = defineProps<{
  placeholder: string;
  value?: string;
  disabled?: boolean;
  autofocus?: boolean;
}>();

const query = ref<string>();

watch(props, () => {
  if (props.value) query.value = props.value;
});

const checkInput = (e: KeyboardEvent) => {
  if (e.key === 'Enter') emit('enter', query.value ?? props.placeholder);
};

const inputQuery = ref<HTMLInputElement>();

onMounted(() => {
  if (props.autofocus) inputQuery.value?.focus();
});
</script>
<template>
  <label>
    <Icon
      icon="prime:search"
      width="20px"
      height="20px"
      @click="$emit('iconClicked', query ?? placeholder)"
    />
    <input
      type="search"
      v-model="query"
      @keydown="checkInput"
      :placeholder="placeholder"
      :disabled="disabled"
      :autofocus="autofocus"
      ref="inputQuery"
    />
  </label>
</template>
<style scoped lang="scss">
@import '~scss/index';

label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;
  border-radius: 20px;
  background: $bg-weak;
  flex-grow: 1;

  .iconify {
    color: $dark-icon-color;
    flex-shrink: 0;
  }

  input[type='search'] {
    all: unset;
    width: 100%;
    font-size: 0.925rem;
    font-style: normal;
    line-height: normal;
    color: $dark-icon-color;
    cursor: text;

    ::placeholder {
      color: $placeholder-color;
      font-weight: 300;
    }
  }
}
</style>
