<script setup lang="ts">
import router from '@/router';
import { ProductOrder } from '@/services/products.service';
import store from '@/store';
import { computed } from 'vue';

const productsInCart = computed<ProductOrder[] | never[]>(
  () => store.state.cart ?? '[]',
);
const cartContentLength = computed<number>(() => productsInCart.value.length);
const showCartBadge = computed(() => cartContentLength.value > 0);

const openCart = () => {
  router.push({ name: 'cart' });
};
</script>
<template>
  <div class="badged-icon" @click="openCart">
    <Icon icon="la:shopping-cart" width="26px" height="26px" />
    <span class="badge" v-show="showCartBadge">
      {{ cartContentLength }}
    </span>
  </div>
</template>
<style lang="scss" scoped>
@import '~scss/index';
.badged-icon {
  position: relative;
  width: max-content;

  .iconify {
    color: $dark-icon-color;
  }

  .badge {
    background-color: $badge-color;
    position: absolute;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: white;
    top: 1px;
    right: -1px;
    font-size: 7px;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
