import { createStore } from 'vuex';
type TemplateMessages = {
  welcome_title: string;
  welcome_message: string;
  product_search_placeholder: string;
  headline_message: string;
  etalase_search_placeholder: string;
  call_center_preserved_message: string;
  no_product_found_message: string;
  product_order_message: string;
  call_center_number: number;
  instagram: string;
  address: string;
  company_name: string;
  brand_name: string;
  logo_url: string;
};

export default createStore({
  state: {
    banners: [],
    categories: [],
    products: [],
    cart: [],
    template_messages: {} as TemplateMessages,
  },
  mutations: {
    SAVE_BANNERS(state, payload: any) {
      state.banners = payload;
    },
    SAVE_CATEGORIES(state, payload: any) {
      state.categories = payload;
    },
    SAVE_PRODUCTS(state, payload: any) {
      state.products = payload;
    },
    UPDATE_CART(state, payload: any) {
      state.cart = payload;
    },
    SAVE_MESSAGES(state, payload: any) {
      state.template_messages = payload;
    },
  },
  actions: {},
  modules: {},
});
