<script setup lang="ts">
import router from '@/router';
import { Icon } from '@iconify/vue';
import { computed, ref, watch } from 'vue';
import { chatWhatsApp } from '@/services/products.service';
const path = computed(
  () => router.currentRoute.value.path ?? location.pathname,
);

const query = computed(() => router.currentRoute.value.query);

const pathToShow = ['/home', '/products', '/etalase'];
const show = computed(
  () => pathToShow.includes(path.value) && !query.value.category,
);

const menuBar = ref<HTMLDivElement>();
const height = computed(() => menuBar.value?.clientHeight);

watch([height, menuBar], () => {
  document.body.style.setProperty('--menu-height', height.value + 'px');
});
</script>
<template>
  <div class="bottom-menu" v-if="show" ref="menuBar">
    <div
      class="bottom-menu__menu-item"
      :class="{ active: path === '/home' }"
      @click="
        () => {
          path = '/home';
          $router.replace({ name: 'home' });
        }
      "
    >
      <Icon icon="iconoir:small-shop-alt" />
      <label>Beranda</label>
    </div>
    <div
      class="bottom-menu__menu-item"
      :class="{ active: path === '/products' }"
      @click="
        () => {
          path = '/products';
          $router.replace({ name: 'products' });
        }
      "
    >
      <Icon icon="carbon:drink-02" />
      <label>Produk</label>
    </div>
    <div
      class="bottom-menu__menu-item"
      :class="{ active: path === '/etalase' }"
      @click="
        () => {
          path = '/etalase';
          $router.replace({ name: 'etalase' });
        }
      "
    >
      <Icon icon="bx:cabinet" />
      <label>Etalase</label>
    </div>
    <div
      class="bottom-menu__menu-item"
      :class="{ active: path === '/call-center' }"
      @click="chatWhatsApp"
    >
      <Icon icon="ic:baseline-whatsapp" />
      <label>Tanya Kami</label>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '~scss/index';
@import '~rfs/scss';
.bottom-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 12px 40px;
  background-color: $bg-white;
  box-shadow: $box-shadow;
  border-radius: 26px;

  .bottom-menu__menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-icon-color;
    gap: 0.15rem;

    .iconify {
      @include rfs(26px, width);
      @include rfs(26px, height);
    }

    label {
      text-align: center;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .active {
    color: $visited-link;
  }
}
</style>
